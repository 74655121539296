var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.patientInfoForEditing)?_c('v-card',{class:_vm.inline ? null : 'flex mb-4 pb-4 px-3',attrs:{"flat":"","color":"grey lighten-5"}},[(_vm.inline)?_c('v-select',{attrs:{"items":_vm.arrayOfProviders,"hide-details":"","dense":"","placeholder":" Add attributed provider","reverse":"","append-icon":_vm.inline ? null : 'mdi-chevron-down',"solo":"","flat":"","background-color":"transparent","disabled":_vm.arrayOfProviders.length === 0},on:{"change":_vm.savePatientInfo},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.providersDict[item].displayName)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.inline)?_c('span',{staticClass:"text-truncate",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.providersDict[item].displayName))]):_c('span',[_vm._v(_vm._s(_vm.providersDict[item].displayName))])]}}],null,false,3282898522),model:{value:(_vm.patientInfoForEditing.attributedProvider),callback:function ($$v) {_vm.$set(_vm.patientInfoForEditing, "attributedProvider", $$v)},expression:"patientInfoForEditing.attributedProvider"}}):_c('v-select',{attrs:{"items":_vm.arrayOfProviders,"hide-details":"","dense":"","outlined":"","label":"Attributed provider","append-icon":_vm.inline ? null : 'mdi-chevron-down'},on:{"change":_vm.savePatientInfo},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.providersDict[item].displayName)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.inline)?_c('span',{staticClass:"text-truncate",staticStyle:{"font-weight":"bold","width":"140px"}},[_vm._v(_vm._s(_vm.providersDict[item].displayName))]):_c('span',[_vm._v(_vm._s(_vm.providersDict[item].displayName))])]}}],null,false,714695575),model:{value:(_vm.patientInfoForEditing.attributedProvider),callback:function ($$v) {_vm.$set(_vm.patientInfoForEditing, "attributedProvider", $$v)},expression:"patientInfoForEditing.attributedProvider"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }