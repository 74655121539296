<template>
  <v-card color="transparent" flat class="flex">
    <v-card v-if="!automatedChartNote" flat outlined rounded="lg" class="flex" >

      <v-row justify="space-between" no-gutters>
        <v-list-item >
          <v-list-item-avatar size="36" class="mr-3" color="primary">
            <v-icon size="20" dark>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium text-subtitle-1">
              {{ note.author }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-medium">
              {{relativeTime(note.timestamp)}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row v-if="note.mentionText" no-gutters>
              <v-chip color="grey lighten-4" >
                <v-icon left class="" small color="primary">mdi-at</v-icon>
                <span>{{note.mentionText}}</span>
              </v-chip>
              <v-chip class="px-1" color="transparent">
                <span style="opacity:0.5">{{note.additionalMentions}}</span>
              </v-chip>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-row>
      <!-- the text or media should start immediately after this. no top padding. this needs to feel super lightweight and data dense-->
      <chart-note-media class="mb-2" :item="note"/>
      <p :id="note.timestamp"  class="font-weight-regular text-body-1 px-4 pt-0 pb-0 " >{{note.text}}</p>
      <v-divider v-if="note.replies.length > 0" class="mb-5"/>
      <v-row v-for="(reply, index) in note.replies" :key="index" class="mx-4" no-gutters>
        <v-card style="opacity:0.9" flat class="mb-1 pb-1 pt-0">
          <v-row align="start" no-gutters>
            <v-avatar class="mr-3" size="28" color="grey lighten-2">
            <v-icon size="16">mdi-account</v-icon>
            </v-avatar>
            <v-col>
            <v-row align="center" no-gutters class="font-weight-medium text-subtitle-1">
            {{reply.author}}<span class="font-weight-regular text-subtitle-2 ml-1 text--secondary"> ∙ {{relativeTime(reply.timestamp)}}</span>
            </v-row>
            <v-card-text class="font-weight-regular text-body-1 px-0 pt-0 pb-0">
            {{reply.text}}
          </v-card-text>
            </v-col>
          </v-row>
          
        </v-card>
      </v-row>
      <v-row class="mx-4 mb-4 mt-2" no-gutters>
      <v-textarea :loading="savingResponse" v-model="newComment" @keydown.enter.stop.prevent="saveResponse()" hide-details placeholder="Write a comment"  solo flat background-color="grey lighten-4" row-height="16" rows="1" auto-grow>
      </v-textarea>
      </v-row>
    </v-card>

    <v-row class="px-2" align="center" v-if="automatedChartNote" no-gutters>
      <v-divider/>
      <v-chip color="grey lighten-4" class="mx-3 text-caption ">
        <v-avatar left >
          <v-icon small :color="autoIconColor">{{automatedChartNoteIcon}}</v-icon>
        </v-avatar>
        <span class="d-inline-block text-truncate" style="max-width: 350px;">
          {{ note.author }} {{automatedChartActionText}} {{automatedChartActionItemText}}
        </span> 
        <span class="text--secondary ml-1">{{relativeTime(note.timestamp)}}</span>
      </v-chip>
      <v-divider/>
    </v-row>
  </v-card>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions } from 'vuex'
import moment from "moment-timezone";
import ChartNoteMedia from './ChartNoteMedia.vue';
import bus from '@/core/helpers/bus'

export default {
  components: { ChartNoteMedia },
  mixins: [HelperMixin],
  props: {
    note: Object,
  },
  data() {
    return {
      newComment: '',
      savingResponse: false,
      selectedMentions: []
    }
  },
  methods: {
    ...mapActions('patient', ['addNote']),
    saveResponse() {
      if (this.newComment === '') {
        return
      }
      this.savingResponse = true
      let noteMentions = []
      if (this.selectedMentions.length > 0) {
        this.selectedMentions.forEach(mention => {
          let userTag = {name: mention.displayName, userID: mention.id}
          noteMentions.push(userTag)
        });
      }
      let response = {
        content: this.newComment,
        referenceData: {thread: this.note.id, type: 'response'},
        noteMentions: [{name: this.note.fullNote.data.authorName, userID: this.note.fullNote.data.user}]
      }
      this.addNote(response).then(() => {
        response.data = {referenceData: {thread: this.note.id, type: 'response'}}
        response.text = response.sessionNote
        response.author = this.user.firstName + ' ' + this.user.lastName
        response.timestamp = new Date().getTime()
        this.note.replies.push(response)
        bus.$emit('toast', { type: 'success', text: 'Response Saved' })
        this.newComment = ''
        this.savingResponse = false
        this.$emit('noteSaved')
      })
    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value;

      let days = Difference_In_Time / (3600000 * 24);
      
      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 6) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).calendar();
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return "";
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    automatedChartNote() {
      // this is for system generated notes.
      return this.note.fullNote?.data?.type === 'auto'
    },
    automatedChartNoteIcon() {
      const icons = { condition: 'mdi-bandage', medication: 'mdi-pill', telehealth: 'mdi-video'}
      return icons[this.note.fullNote?.data?.referenceData?.type] || 'mdi-alert'
    },
    automatedChartActionText() {
      if (this.note.fullNote?.data?.action === 'add') {
        return 'added'
      }
      if (this.note.fullNote?.data?.action === 'remove') {
        return 'removed'
      }
      return null
    },
    autoIconColor() {
      if (this.automatedChartActionText === 'added') {
        return 'primary'
      }
      return 'red'
    },
    automatedChartActionItemText() {
      const rd = this.note.fullNote?.data?.referenceData
      if (rd?.type === 'condition') {
        if (rd.data?.fullItem?.description) {
          return rd.data.fullItem.description
        } else {
          return rd.data.name
        }
      }
      if (rd?.type === 'medication') {
        return rd.data.name
      }
      return null
    },
  },
}
</script>