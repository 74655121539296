<template>
  <v-card class="flex pt-0" style="overflow-y:auto" tile color="transparent" flat>
    <v-divider class="mb-2" />
    <span class="text-body-2 font-weight-medium text--secondary">Chart Notes</span>
    <v-row no-gutters>
      <v-col>
        <v-card
          v-if="noteTypes.length"
          class="text-subtitle-1 mb-2 py-0 pt-1"
          style="position: sticky; top: 0;"
          tile
          flat
          color="transparent"
        >
          <span class="text-subtitle-2">Note Types</span>

          <v-row
            class="py-1"
            align="center"
            no-gutters
            justify="space-between"
            v-for="type in noteTypes"
            :key="type.title"
          >
            <span class="text-subtitle-2 font-weight-regular text--secondary">{{ type.title }}</span>
            <span class="text-subtitle-2 font-weight-regular">{{ type.count }}</span>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  props: {
    noteTypes: Array,
  },
}
</script>
