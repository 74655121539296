<template>
  <v-card
    :style="chart ? null : 'pointer-events: none;'"
    :color="chart ? null : 'grey lighten-5'"
    flat
    tile
    class="flex"
  >
    <v-card color="transparent" flat height="50" class="flex">
      <v-fade-transition>
        <v-row v-if="chart" no-gutters align="center" class="fill-height px-2">
          <span style="font-weight: bold; opacity: 0.7">{{ visibleDateRange }}</span>
          <v-spacer />
          <v-btn @click="scootLeft" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
          <v-btn
            icon
            :style="span !== 8 ? 'background-color: transparent' : 'background-color: #2196F3'"
            :color="span !== 8 ? 'primary' : 'white'"
            @click="goToSeven"
            >7</v-btn
          ><v-btn
            icon
            :style="span !== 31 ? 'background-color: transparent' : 'background-color: #2196F3'"
            :color="span !== 31 ? 'primary' : 'white'"
            @click="goToThirty"
            >30</v-btn
          ><v-btn
            icon
            :style="span !== 91 ? 'background-color: transparent' : 'background-color: #2196F3'"
            :color="span !== 91 ? 'primary' : 'white'"
            @click="goToNinety"
            >90</v-btn
          >
          <v-btn @click="scootRight" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
          <v-btn @click="resetChart()" icon><v-icon size="20">mdi-reload</v-icon></v-btn>
        </v-row>
      </v-fade-transition>
    </v-card>

    <v-card :height="heightOfGraph" tile color="transparent" style="position: relative" flat>
      <!-- <div
        style="width: calc(100% - 30px); height: 100%; position: absolute; background-color: transparent; left: 30px"
      ></div> -->
      <div
        id="spanSelector"
        style="
          position: absolute;
          height: 100%;
          top: 0px;
          margin-left: 0px;
          width: 1px;
          background-color: #4485ed4d;
          display: none;
          pointer-events: none;
        "
      ></div>

      <canvas style="z-index: 10" id="planet-chart"></canvas>
    </v-card>

    <!-- <v-card class="text-caption" color="transparent" flat tile width="flex" height="30" style="margin-top: -25px">
      <v-fade-transition>
        <v-row v-if="chart" no-gutters class="fill-height" align="center">
          <span style="background-color: white; padding-left: 5px; padding-right: 5px; margin-left: 2px">{{
            visibleStartPoint
          }}</span
          ><v-col> <v-divider /></v-col
          ><span style="background-color: white; padding-left: 5px; padding-right: 5px; margin-right: 2px">{{
            visibleEndPoint
          }}</span>
        </v-row>
      </v-fade-transition>
    </v-card> -->

    <!-- <v-card height="50" tile color="grey lighten-4" style="position: relative; pointer-events: none;" flat>
      <div id="underchartHighlight" :style="underchartHiglightStyling"></div>
      <canvas style="z-index: 10" id="underchart"></canvas>
    </v-card> -->

    <!-- <v-fade-transition>
      <div v-if="!visibleReadings" style="position: absolute; top:50px; width: 100vw; height: 150px">
        <v-row no-gutters class="fill-height flex" align="center" justify="center">
          <v-chip>No data in the last {{ span }} Days</v-chip>
        </v-row>
      </div>
    </v-fade-transition> -->
  </v-card>
</template>

<script>
import Chart from 'chart.js'
import 'chartjs-adapter-date-fns'
import dayjs from 'dayjs'

export default {
  name: 'PlanetChart',
  props: {
    mostRecentData: {
      type: Number,
      default: 0,
    },
    datasets: {
      type: Array,
      default: null,
    },
    underchartDatasets: {
      type: Array,
      default: null,
    },
    heightOfGraph: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      span: 8,
      chart: null,
      underchart: null,
      endPoint: null,
      hoverpoint: null,
      isDragging: false,
      downpoint: null,
      upPoint: null,
      downX: null,
      upX: null,
      selectedLength: null,
      mousePoint: null,
    }
  },
  watch: {
    datasets: {
      handler(val) {
        if (this.chart && val && val.length > 0) {
          this.chart.data.datasets = this.datasets
          this.chart.update()
          // this.underchart.data.datasets = this.underchartDatasets
          // this.underchart.update()
        } else if (val && val.length > 0) {
          const ctx = document.getElementById('planet-chart')
          this.chart = new Chart(ctx, this.chartData)

          // const ctx2 = document.getElementById('underchart')
          // this.underchart = new Chart(ctx2, this.underChartData)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.endPoint = this.getEndOfDay()
    if (this.datasets && this.datasets.length > 0) {
      const ctx = document.getElementById('planet-chart')
      this.chart = new Chart(ctx, this.chartData)

      // const ctx2 = document.getElementById('underchart')
      // this.underchart = new Chart(ctx2, this.underChartData)
    }
    document.getElementById('planet-chart').addEventListener('pointerdown', this.handleDown, false)
    document.getElementById('planet-chart').addEventListener('pointerup', this.handleUp, false)
    document.getElementById('planet-chart').addEventListener('pointercancel', this.handleCancel, false)
    document.getElementById('planet-chart').addEventListener('pointermove', this.handleHover, false)
    document.getElementById('planet-chart').addEventListener('pointerout', this.handleCancel, false)
    document.getElementById('planet-chart').addEventListener('pointerleave', this.handleCancel, false)
  },
  computed: {
    // underchartHiglightStyling() {
    //   console.log('start', this.startPoint)
    //   console.log('end', this.endPoint)

    //   var bounds = document.getElementById('underchart').getBoundingClientRect()
    //   let startOfSpan = this.getEndOfDay() - 86400000 * 365

    //   let timespan = this.getEndOfDay() - (this.getEndOfDay() - 86400000 * 365)
    //   let boundingWidth = bounds.width

    //   console.log('bounding width: ', boundingWidth)

    //   let percentageOfWidthAtStart = (this.startPoint - startOfSpan) / timespan
    //   let endToUse = this.endPoint
    //   if (!this.endPoint) {
    //     endToUse = this.getEndOfDay()
    //   }

    //   let percentageOfWidthAtEnd = (endToUse - startOfSpan) / timespan

    //   let percentageOfWidthLength = percentageOfWidthAtEnd - percentageOfWidthAtStart

    //   let startX = boundingWidth * percentageOfWidthAtStart

    //   let lengthOfArea = boundingWidth * percentageOfWidthLength

    //   let xLocOfStart = startX

    //   let length = lengthOfArea

    //   console.log(
    //     'position: absolute; width: ' +
    //       length +
    //       'px; top: 2px; height: 46px; left: ' +
    //       xLocOfStart +
    //       'px; background-color: white;'
    //   )

    //   return (
    //     'position: absolute; width: ' +
    //     length +
    //     'px; top: 2px; height: 46px; left: ' +
    //     xLocOfStart +
    //     'px; background-color: #4485ed4d; z-index: 1'
    //   )
    // },
    visibleStartPoint() {
      let format = 'MMM D'
      if (this.span < 1) {
        format = 'MMM DD h:mm a'
      }
      let start = dayjs(this.startPoint).format(format)

      if (start === dayjs().format(format)) {
        return 'Today'
      }

      return start
    },
    visibleEndPoint() {
      let format = 'MMM D'
      if (this.span < 1) {
        format = 'MMM D h:mm a'
      }
      let end = dayjs(this.endPoint).format(format)

      if (end === dayjs().format(format)) {
        return 'Today'
      }
      return end
    },
    visibleReadings() {
      return this.mostRecentData > this.startPoint
    },
    startPoint() {
      return this.endPoint - 86400000 * this.span
    },
    visibleDateRange() {
      // determine how much time 35 pixels represents?

      let start = dayjs(this.chart.scales['x-axis-0'].min + 1000).format('MMM D')
      let end = dayjs(this.endPoint).format('MMM D')

      if (end === dayjs().format('MMM D') && start !== dayjs().format('MMM D')) {
        return start + ' - Today'
      }
      if (end === dayjs().format('MMM D') && start === dayjs().format('MMM D')) {
        return 'Today'
      }

      if (end === start) {
        return end
      }

      return start + ' - ' + end
    },
    chartData() {
      if (this.datasets && this.datasets.length > 0) {
        return {
          type: 'line',

          interaction: {
            mode: 'nearest',
          },
          data: {
            datasets: this.datasets,
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0,
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  type: 'time',
                  time: {
                    unit: 'day',
                  },
                  distribution: 'linear',
                  ticks: {
                    max: this.endPoint,
                    min: this.startPoint,
                    display: true,
                  },
                  drawBorder: false,
                },
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    min: 0,
                    display: true,
                  },
                  drawBorder: false,
                },
              ],
            },
          },
        }
      }
      return null
    },
    underChartData() {
      if (this.datasets && this.datasets.length > 0) {
        return {
          type: 'line',

          interaction: {
            mode: 'nearest',
          },
          data: {
            datasets: this.underchartDatasets,
          },
          options: {
            tooltips: {
              enabled: false,
            },
            responsive: true,
            maintainAspectRatio: false,
            onHover: this.handleHover,
            legend: {
              display: false,
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 10,
              },
            },
            scales: {
              xAxes: [
                {
                  display: false,

                  type: 'time',
                  distribution: 'linear',
                  ticks: {
                    max: this.getEndOfDay(),
                    min: this.getEndOfDay() - 86400000 * 365,
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  display: false,
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
          },
        }
      }
      return null
    },
  },
  methods: {
    getEndOfDay() {
      let dateRightNow = new Date()

      dateRightNow.setHours(23, 59, 59, 999)
      return dateRightNow.getTime()
    },
    resetChart() {
      this.span = 8
      this.endPoint = this.getEndOfDay()
      this.chart.options.scales.xAxes[0].ticks.min = this.startPoint
      this.chart.options.scales.xAxes[0].ticks.max = this.endPoint
      this.chart.options.scales.xAxes[0].time.unit = 'day'
      this.chart.update()
    },
    handleHover(event) {
      var bounds = event.target.getBoundingClientRect()
      let translatedX = event.x - bounds.x
      let timespan = this.endPoint - this.startPoint
      let horizLocation = (translatedX / bounds.width) * timespan + this.startPoint
      this.mousePoint = translatedX
      this.hoverpoint = dayjs(horizLocation).format('MMM DD')
      document.getElementById('spanSelector').style.display = 'inline-block'

      if (this.isDragging) {
        this.selectedLength = Math.abs(this.downX - translatedX)
        if (translatedX < this.downX) {
          document.getElementById('spanSelector').style.marginLeft = translatedX + 'px'
          document.getElementById('spanSelector').style.width = Math.abs(this.downX - translatedX) + 'px'
        } else {
          document.getElementById('spanSelector').style.marginLeft = this.downX + 'px'
          document.getElementById('spanSelector').style.width = Math.abs(this.downX - translatedX) + 'px'
        }
        // document.getElementById('spanSelector').style.marginLeft = this.downX + 'px'
        document.getElementById('spanSelector').style.border = '1px solid #4485ed;'
      } else {
        document.getElementById('spanSelector').style.marginLeft = translatedX + 'px'
        document.getElementById('spanSelector').style.width = 1 + 'px'
        document.getElementById('spanSelector').style.border = null
      }

      //document.getElementById('crosshair').style.marginLeft = event.x + 'px'
    },
    handleDown(event) {
      this.isDragging = true
      // check for data point near event location

      var bounds = event.target.getBoundingClientRect()
      let translatedX = event.x - bounds.x
      let timespan = this.endPoint - this.startPoint
      let horizLocation = (translatedX / bounds.width) * timespan + this.startPoint
      this.downpoint = horizLocation
      this.downX = translatedX
    },
    handleUp(event) {
      this.isDragging = false
      var bounds = event.target.getBoundingClientRect()
      let translatedX = event.x - bounds.x
      let timespan = this.endPoint - this.startPoint
      let horizLocation = (translatedX / bounds.width) * timespan + this.startPoint

      let graph = document.getElementById('planet-chart').getBoundingClientRect()

      console.log(this.chart.scales['x-axis-0'].min + 1000)

      let thePixelsInSeconds = (36 / graph.width) * (86400000 * this.span)

      console.log('pixels in seconds', thePixelsInSeconds)

      if (this.downpoint > horizLocation) {
        this.upPoint = this.downpoint
        this.downpoint = horizLocation
      } else {
        this.upPoint = horizLocation
      }

      this.downpoint = this.downpoint - thePixelsInSeconds
      // this.upPoint = horizLocation
      // this.upX = event.x

      this.zoomTo(parseInt(this.upPoint), parseInt(this.downpoint))

      this.downpoint = null
      this.upPoint = null
      this.upx = null
      this.downX = null

      //document.getElementById('spanSelector').style.display = 'none'
      document.getElementById('spanSelector').style.width = 1 + 'px'
      document.getElementById('spanSelector').style.marginLeft = event.x + 'px'
    },
    handleCancel(event) {
      console.log('handling cancel')
      this.isDragging = false
      // release grabbed point, stop dragging
      this.downpoint = null
      this.upPoint = null
      this.upx = null
      this.downX = null
      //document.getElementById('spanSelector').style.display = 'none'
      document.getElementById('spanSelector').style.marginLeft = event.x + 'px'
      document.getElementById('spanSelector').style.width = 1 + 'px'
      document.getElementById('spanSelector').style.display = 'none'
    },
    handleMove() {},
    scootLeft() {
      this.endPoint = this.endPoint - 86400000 * this.span
      this.chart.options.scales.xAxes[0].ticks.min = this.startPoint
      this.chart.options.scales.xAxes[0].ticks.max = this.endPoint
      this.chart.update()
    },

    scootRight() {
      this.endPoint = this.endPoint + 86400000 * this.span
      if (this.endPoint > this.getEndOfDay()) {
        this.endPoint = this.getEndOfDay()
      }
      this.chart.options.scales.xAxes[0].ticks.min = this.startPoint
      this.chart.options.scales.xAxes[0].ticks.max = this.endPoint
      this.chart.update()
    },

    zoomTo(start, end) {
      if (isNaN(start) || isNaN(end)) {
        return
      }

      console.log('start point ', start)
      console.log('end point ', end)
      //get the span?
      let tempStart = start

      if (start > end) {
        tempStart = end
        this.span = Math.abs(start - tempStart) / 86400000
        this.endPoint = start
      } else {
        this.endPoint = end
        this.span = Math.abs(end - tempStart) / 86400000
        this.endPoint = start
      }

      this.chart.options.scales.xAxes[0].ticks.min = this.startPoint
      this.chart.options.scales.xAxes[0].ticks.max = this.endPoint

      if (this.span < 1 / 24) {
        this.chart.options.scales.xAxes[0].time.unit = 'minute'
      } else if (this.span < 1) {
        this.chart.options.scales.xAxes[0].time.unit = 'hour'
      } else if (this.span < 31) {
        this.chart.options.scales.xAxes[0].time.unit = 'day'
      } else {
        this.chart.options.scales.xAxes[0].time.unit = 'month'
      }

      this.chart.update()
    },

    goToSeven() {
      this.endPoint = this.getEndOfDay()
      this.span = 8
      this.chart.options.scales.xAxes[0].ticks.min = this.startPoint
      this.chart.options.scales.xAxes[0].ticks.max = this.endPoint
      this.chart.options.scales.xAxes[0].time.unit = 'day'
      this.chart.update()
    },
    goToThirty() {
      this.endPoint = this.getEndOfDay()
      this.span = 31
      this.chart.options.scales.xAxes[0].ticks.min = this.startPoint
      this.chart.options.scales.xAxes[0].ticks.max = this.endPoint
      this.chart.options.scales.xAxes[0].time.unit = 'week'
      this.chart.update()
    },
    goToNinety() {
      this.endPoint = this.getEndOfDay()
      this.span = 91
      this.chart.options.scales.xAxes[0].ticks.min = this.startPoint
      this.chart.options.scales.xAxes[0].ticks.max = this.endPoint
      this.chart.options.scales.xAxes[0].time.unit = 'month'
      this.chart.update()
    },
  },
}
</script>
