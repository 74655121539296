import camelCase from 'lodash/camelCase'

export const initials = (val = '') => {
  let names = val.split(' ')
  if (names.length > 0) {
    return names[0].substring(0, 1).toUpperCase() + names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return names[0].substring(0, 1).toUpperCase()
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const fixEventKind = eventKind => {
  eventKind = eventKind.replace('MEASUREMENT_', '')
  console.log('fixEventKind:', eventKind, capitalizeFirstLetter(camelCase(eventKind)))
  return capitalizeFirstLetter(camelCase(eventKind))
}

export const timeAgo = ms => {
  var msPerMinute = 60 * 1000
  var msPerHour = msPerMinute * 60
  var msPerDay = msPerHour * 24
  var msPerMonth = msPerDay * 30
  var msPerYear = msPerDay * 365
  if (ms < msPerMinute) {
    let seconds = Math.round(ms / 1000)
    return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`
  } else if (ms < msPerHour) {
    let minutes = Math.round(ms / msPerMinute)
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
  } else if (ms < msPerDay) {
    let hours = Math.round(ms / msPerHour)
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
  } else if (ms < msPerMonth) {
    let days = Math.round(ms / msPerDay)
    return `${days} ${days === 1 ? 'day' : 'days'} ago`
  } else if (ms < msPerYear) {
    let months = Math.round(ms / msPerMonth)
    return `${months} ${months === 1 ? 'month' : 'months'} ago`
  } else {
    let years = Math.round(ms / msPerYear)
    return `${years === 1 ? 'a year' : years + ' years'} ago`
  }
}
export const formatTemp = (temp, isFahrenheit) => {
  if (isFahrenheit) {
    return temp + '°'
  } else {
    return temp + '°C'
  }
}

export const toFahrenheit = (celsius, format) => {
  // keep a single decimal place for fahrenheit
  let temp = parseFloat(celsius * (9 / 5) + 32).toFixed(1)
  if (format) {
    return formatTemp(temp, true)
  }
  return temp
}

export const toCelsius = (fahrenheit, format) => {
  // keep 2 decimal places for celsius
  let temp = parseFloat((fahrenheit - 32) * (5 / 9)).toFixed(2)
  if (format) {
    return formatTemp(temp)
  }
  return temp
}

export const validatePhone = phone => {
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  return regex.test(phone)
}
