<template>
  <v-fade-transition>
    <v-hover v-slot="{ hover }">
      <v-card outlined flat>
        <v-card v-show="show" @click="$emit('selected')" flat :class="`${hover ? hoverClass : unhoverClass}`">
          <v-row class="flex" align="start" no-gutters>
            <div v-if="picker" style="margin-left:-5px; margin-top:-1px">
              <v-icon size="14" class="mr-1" color="primary" v-if="picker && included">mdi-check-circle</v-icon>
              <v-icon size="14" class="mr-1" v-if="picker && !included">mdi-checkbox-blank-circle-outline</v-icon>
            </div>
            <v-col>
              <v-row :class="titleTextClass" no-gutters justify="space-between">
                <!-- have to pass the metric dictionary stuff with it.-->
                <span>{{ metricToDisplay.commonName }}</span><span class="ml-2 text--secondary"> {{ relativeTime(metricToDisplay.latestEvent.timestamp) }}</span>
              </v-row>

              <v-row align="start" class="mt-0" no-gutters justify="space-between">
                <v-col>
                  <v-card color="transparent" flat :height="graphThumbnailCardHeight">
                    <v-row align="end" no-gutters class="fill-height">
                      <span v-if="metricToDisplay.latestEvent.displayValue" :class="metricValueTextClass">
                        {{ metricToDisplay.latestEvent.displayValue }}
                      </span>
                      <span :class="metricUnitsTextClass">
                        {{ metricToDisplay.units }}
                      </span>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col v-if="showChart">
                  <v-row no-gutters align="center" justify="end" class="fill-height ml-6">
                    <v-card
                      :height="graphThumbnailCardHeight"
                      :width="graphThumbnailCardWidth"
                      flat
                      color="transparent"
                    >
                      <v-row no-gutters align="center" class="fill-height" justify="center">
                        <vue-apex-charts
                          :options="chartOptionsMini"
                          :height="graphThumbnailHeight"
                          :width="graphThumbnailWidth"
                          :series="series"
                        ></vue-apex-charts>
                      </v-row>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
              <v-card
                v-if="!compact && metricToDisplay.latestEvent.alert"
                height="14"
                class="mt-2"
                flat
                color="transparent"
              >
                <v-row
                  v-if="metricToDisplay.latestEvent.alert"
                  class="text--disabled font-weight-medium text-caption"
                  no-gutters
                >
                  <v-icon class="mr-1" size="14" color="yellow">mdi-alert-circle</v-icon>OUT OF RANGE
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-hover>
  </v-fade-transition>
</template>
<style scoped>
.example {
  color: red;
}
.labelClass {
  background-color: green;
}
.cardGrid {
  background-size: 20px 20px;
  background-image: linear-gradient(to right, #f5f5f5 1px, transparent 1px),
    linear-gradient(to bottom, #f5f5f5 1px, transparent 1px);

  background-repeat: repeat;
}
.v-sheet.v-card {
  box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}
.softBoxShadow {
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
}
.apexcharts-tooltip {
  transform: translateY(-60px);
  visibility: hidden;
}
.alerted {
  background-color: #f7db03;
}
.defaultColor {
  background-color: #fafafa;
}
</style>

<script>
import VueApexCharts from 'vue-apexcharts'
import HelperMixin from '@/core/mixins/HelperMixin'
import moment from 'moment-timezone'

export default {
  mixins: [HelperMixin],
  props: {
    picker: {
      type: Boolean,
      default: false,
    },
    included: {
      type: Boolean,
      default: true,
    },
    metricToDisplay: {
      type: Object,
      default: null,
    },
    specificRange: {
      type: Boolean,
      default: false,
    },
    elevatedCard: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    mobileStyle: {
      type: Boolean,
      default: false,
    },
    showChart: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      show: false,
      metricName: null,
      metric: null,
      events: null,
      seriesProto: null,
    }
  },
  computed: {
    includedColor() {
      if (this.included) {
        return '#ffffff'
      } else {
        return 'grey lighten-4'
      }
    },
    colorsForChart() {
      let colors = []
      if (this.metricToDisplay.kind === 'BloodPressure') {
        // green and blue?
        colors.push('#008FFB')
        colors.push('#00E396')
      } else if (this.metricToDisplay.kind === 'Pulse') {
        // green and blue?
        colors.push('#E80305')
      } else if (this.metricToDisplay.kind === 'BloodGlucose') {
        // green and blue?
        colors.push('#07A880')
      } else if (this.metricToDisplay.kind === 'BodyWeight') {
        // green and blue?
        colors.push('#2D302E')
      } else if (this.metricToDisplay.kind === 'Ecg') {
        // green and blue?
        colors.push('#2D302E')
      }
      return colors
    },
    hoverClass() {
      return this.cardPaddingClass + ' grey lighten-5'
    },
    unhoverClass() {
      return this.cardPaddingClass + ' ' + this.includedColor
    },
    wrapperCardClass() {
      if (this.elevatedCard) {
        return 'elevation-2'
      }
      return null
    },
    cardPaddingClass() {
      if (this.compact) {
        return 'pa-3 pt-2 ' + this.wrapperCardClass
      }

      if (this.mobileStyle) {
        return 'pa-3 pt-3 pb-5 ' + this.wrapperCardClass
      }

      return 'pa-3 ' + this.wrapperCardClass
    },
    titleTextClass() {
      if (this.compact) {
        return 'font-weight-light text-subtitle-2 '
      }

      if (this.mobileStyle) {
        return 'font-weight-light text-subtitle-1 '
      }

      return 'font-weight-light text-body-2'
    },
    metricValueTextClass() {
      if (this.compact) {
        return 'font-weight-light text-h5'
      }

      if (this.mobileStyle) {
        return 'font-weight-light text-h3'
      }
      return 'font-weight-light text-h3 '
    },
    metricUnitsTextClass() {
      if (this.compact) {
        return 'font-weight-light text-subtitle-1  ml-2'
      }

      if (this.mobileStyle) {
        return 'font-weight-light text-subtitle-1  ml-2'
      }

      return 'font-weight-light text-h6 ml-2'
    },
    graphThumbnailWidth() {
      if (this.compact) {
        return 70
      }

      if (this.mobileStyle) {
        return 125
      }

      return 145
    },
    graphThumbnailHeight() {
      if (this.compact) {
        return 30
      }
      if (this.mobileStyle) {
        return 55
      }

      return 40
    },
    graphThumbnailCardWidth() {
      if (this.compact) {
        return 75
      }
      if (this.mobileStyle) {
        return 125
      }
      return 140
    },
    graphThumbnailCardHeight() {
      if (this.compact) {
        return 40
      }

      if (this.mobileStyle) {
        return 55
      }

      return 60
    },
    series() {
      if (this.seriesProto) {
        return this.seriesProto
      }
      return []
    },
    chartOptionsMini() {
      // only return the last 30 days in the upper sparkline
      let start = new Date()

      start.setHours(0, 0, 0, 0)

      start.setDate(start.getDate() - 31)

      let end = new Date()

      end.setHours(11, 59, 59, 0)

      let options = {
        chart: { sparkline: { enabled: true }, animations: { enabled: false } },
        colors: this.colorsForChart,
        stroke: { width: 2, curve: 'smooth' },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          forceNiceScale: false,
          type: 'datetime',
          show: false,
        },
      }

      if (!this.specificRange) {
        options.xaxis.max = end.getTime()
        options.xaxis.min = start.getTime()
      }

      return options
    },
  },
  methods: {
    relativeTime(value) {
      if (this.compact) {
        if (value) {
          let dt = moment(value)
            .tz(moment.tz.guess())
            .format('MM/DD/YYYY h:mm a')
          if (dt.includes('12:00 am')) {
            return 'Today'
          } else {
            return moment(value)
              .tz(moment.tz.guess())
              .fromNow()
          }
        }
        return ''
      }
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 7) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
  },
  mounted() {
    this.metricName = this.metricToDisplay.kind
    this.metric = this.metricToDisplay.latestEvent
    this.seriesProto = this.metricToDisplay.series
    this.show = true
  },
}
</script>
