<template>
  <v-container class="pa-0" fluid>
    <div style="height: 600px; overflow: auto">
      <v-card
        color="grey lighten-5"
        style="border-bottom: 1px solid #eeeeee; font-weight: bold"
        tile
        flat
        class="flex novo-sticky-header"
      >
        <metric-plot :mostRecentData="parseInt(sortedArrayOfTimestamps[0])" :datasets="datasets" />

        <v-row v-if="sortedArrayOfTimestamps.length > 0" class="fill-height" align="center" no-gutters>
          <div style="width: 180px" class="text-subtitle-2 pa-1 px-2 font-weight-bold">Date</div>
          <v-col
            style="text-align: center; border-left: 1px solid #eeeeee"
            v-for="(metric, index) in metricsForThisPatient"
            :key="index"
            class="text-subtitle-2 font-weight-bold"
          >
            <div style="text-align: center" class="flex">
              <v-row no-gutters align="center" class="fill-height" justify="center">
                {{ CONSTS.supportedMetrics[metric].abbreviation }}
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row
        style="border-bottom: 1px solid #eeeeee"
        align="center"
        no-gutters
        v-for="timestamp in sortedArrayOfTimestamps"
        :key="timestamp"
      >
        <div style="width: 180px" class="px-2 pt-1">
          <div class="text-subtitle-2 text--secondary">
            {{ specificRelativeTimeJustDate(parseInt(timestamp)) }}
            <span style="opacity: 0.8">
              {{ specificRelativeTimeJustHoursAndMinutes(parseInt(timestamp)) }}
            </span>
          </div>
        </div>
        <v-col
          style="text-align: center; border-left: 1px solid #eeeeee"
          v-for="(metric, index) in metricsForThisPatient"
          :key="index"
          class="text-body-2 fill-height"
        >
          <div class="flex">
            <v-row style="text-align: center" justify="center" align="center" no-gutters class="fill-height">
              <v-icon
                v-if="eventsByTimestamp[timestamp][metric]"
                :color="eventsByTimestamp[timestamp][metric].alert ? '#FFEB3B' : null"
                class="mr-1"
                small
                >{{ CONSTS.supportedMetrics[metric].icon }}</v-icon
              >
              <span v-if="eventsByTimestamp[timestamp][metric]"
                >{{ normalizeValue(eventsByTimestamp[timestamp][metric])
                }}<span class="text--secondary ml-1">{{ CONSTS.supportedMetrics[metric].units }}</span></span
              >
              <span v-else>-</span>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import CONSTS from '../../consts'
import { getPatientEvents } from '../../shared-ui/store/rpm'
import { daysPast } from '../../helpers/time.js'
import { normalizeValue } from '../../nurse-sidekick'
import MetricPlot from '@/components/patientchart/MetricPlot.vue'
import { specificRelativeTimeJustHoursAndMinutes, specificRelativeTimeJustDate } from '@/helpers/time'

export default defineComponent({
  components: { MetricPlot },
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const supportedTypes = Object.keys(CONSTS.supportedMetrics)
    const eventsByKind = ref(null)

    const displayTab = ref(0)

    const typeMapping = {
      MEASUREMENT_BLOOD_GLUCOSE: 'BloodGlucose',
      MEASUREMENT_BLOOD_PRESSURE: 'BloodPressure',
      MEASUREMENT_PULSE: 'Pulse',
      MEASUREMENT_ECG: 'Ecg',
      MEASUREMENT_BODY_WEIGHT: 'BodyWeight',
      MEASUREMENT_BLOOD_OXYGEN: 'BloodOxygen',
      BloodOxygen: 'MEASUREMENT_BLOOD_OXYGEN',
      BloodGlucose: 'MEASUREMENT_BLOOD_GLUCOSE',
      BloodPressure: 'MEASUREMENT_BLOOD_PRESSURE',
      Pulse: 'MEASUREMENT_PULSE',
      Ecg: 'MEASUREMENT_ECG',
      BodyWeight: 'MEASUREMENT_BODY_WEIGHT',
      BodyTemp: 'MEASUREMENT_BODY_TEMPERATURE',
      Pain: 'MEASUREMENT_PAIN',
    }

    const metricsForThisPatient = computed(() => {
      return supportedTypes.filter(type => {
        return Object.keys(props.patient.latestEvents).includes(typeMapping[type])
      })
    })

    const getChartEvents = async () => {
      console.log('eents')
      const timePeriod = { end: new Date(), start: daysPast(182) }
      let events = {}
      await Promise.all(
        supportedTypes.map(async eventName => {
          let event = typeMapping[eventName]
          if (metricsForThisPatient.value.includes(eventName)) {
            events[eventName] = await getPatientEvents(props.patient.id, event, timePeriod.start, timePeriod.end)
          }
        })
      )
      eventsByKind.value = events
    }

    const datasets = computed(() => {
      if (eventsByKind.value) {
        let arrayToReturn = []
        if (eventsByKind.value) {
          Object.keys(eventsByKind.value).forEach(kind => {
            if (kind === 'BloodPressure') {
              let dataObjectSystolic = {
                backgroundColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor + '00',
                borderColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor,
                borderWidth: 2,
                radius: 3,
              }
              dataObjectSystolic.label = 'Systolic'
              dataObjectSystolic.data = []
              dataObjectSystolic.pointBackgroundColor = []
              dataObjectSystolic.pointBorderColor = []
              let dataObjectDiastolic = {
                backgroundColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor + '00',
                borderColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor,
                borderWidth: 2,
                radius: 3,
              }
              dataObjectDiastolic.label = 'Diastolic'
              dataObjectDiastolic.data = []
              dataObjectDiastolic.pointBackgroundColor = []
              dataObjectDiastolic.pointBorderColor = []
              eventsByKind.value[kind].forEach(event => {
                if (event.alert) {
                  dataObjectSystolic.pointBackgroundColor.push('#FFEB3B')
                  dataObjectDiastolic.pointBackgroundColor.push('#FFEB3B')
                  dataObjectSystolic.pointBorderColor.push('#FFEB3B')
                  dataObjectDiastolic.pointBorderColor.push('#FFEB3B')
                } else {
                  dataObjectSystolic.pointBackgroundColor.push(
                    CONSTS.metricList[typeMapping['BloodPressure']].cardColor
                  )
                  dataObjectDiastolic.pointBackgroundColor.push(
                    CONSTS.metricList[typeMapping['BloodPressure']].cardColor
                  )
                  dataObjectSystolic.pointBorderColor.push(CONSTS.metricList[typeMapping['BloodPressure']].cardColor)
                  dataObjectDiastolic.pointBorderColor.push(CONSTS.metricList[typeMapping['BloodPressure']].cardColor)
                }
                dataObjectSystolic.data.push({ x: new Date(event.timestamp), y: event.data.systolicValue })
                dataObjectDiastolic.data.push({ x: new Date(event.timestamp), y: event.data.diastolicValue })
              })
              arrayToReturn.push(dataObjectSystolic)
              arrayToReturn.push(dataObjectDiastolic)
            } else {
              let dataObject = {
                backgroundColor: CONSTS.metricList[typeMapping[kind]].cardColor + '00',
                borderColor: CONSTS.metricList[typeMapping[kind]].cardColor,
                borderWidth: 2,
                radius: 3,
              }

              dataObject.label = kind
              dataObject.data = []
              dataObject.pointBackgroundColor = []
              dataObject.pointBorderColor = []

              eventsByKind.value[kind].forEach(event => {
                if (event.alert) {
                  dataObject.pointBackgroundColor.push('#FFEB3B')
                  dataObject.pointBorderColor.push('#FFEB3B')
                } else {
                  dataObject.pointBackgroundColor.push(CONSTS.metricList[typeMapping[kind]].cardColor)
                  dataObject.pointBorderColor.push(CONSTS.metricList[typeMapping[kind]].cardColor)
                }

                dataObject.data.push({ x: new Date(event.timestamp), y: normalizeValue(event) })
              })
              arrayToReturn.push(dataObject)
            }
          })
        }
        return arrayToReturn
      }
      return null
    })

    // const underchartDatasets = computed(() => {
    //   if (eventsByKind.value) {
    //     let arrayToReturn = []
    //     if (eventsByKind.value) {
    //       Object.keys(eventsByKind.value).forEach(kind => {
    //         if (kind === 'BloodPressure') {
    //           let dataObjectSystolic = {
    //             backgroundColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor + '05',
    //             borderColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor,
    //             borderWidth: 1,
    //             pointRadius: 0,
    //             pointHitRadius: 0,
    //           }
    //           dataObjectSystolic.label = 'Systolic'
    //           dataObjectSystolic.data = []
    //           let dataObjectDiastolic = {
    //             backgroundColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor + '05',
    //             borderColor: CONSTS.metricList[typeMapping['BloodPressure']].cardColor,
    //             borderWidth: 1,
    //             pointRadius: 0,
    //             pointHitRadius: 0,
    //           }
    //           dataObjectDiastolic.label = 'Diastolic'
    //           dataObjectDiastolic.data = []
    //           eventsByKind.value[kind].forEach(event => {
    //             dataObjectSystolic.data.push({ x: new Date(event.timestamp), y: event.data.systolicValue })
    //             dataObjectDiastolic.data.push({ x: new Date(event.timestamp), y: event.data.diastolicValue })
    //           })
    //           arrayToReturn.push(dataObjectSystolic)
    //           arrayToReturn.push(dataObjectDiastolic)
    //         } else {
    //           let dataObject = {
    //             backgroundColor: CONSTS.metricList[typeMapping[kind]].cardColor + '05',
    //             borderColor: CONSTS.metricList[typeMapping[kind]].cardColor,
    //             borderWidth: 0,
    //             pointRadius: 0,
    //             pointHitRadius: 0,
    //           }
    //           dataObject.label = kind
    //           dataObject.data = []
    //           eventsByKind.value[kind].forEach(event => {
    //             dataObject.data.push({ x: new Date(event.timestamp), y: normalizeValue(event) })
    //           })
    //           arrayToReturn.push(dataObject)
    //         }
    //       })
    //     }
    //     return arrayToReturn
    //   }
    //   return null
    // })

    const eventsByTimestamp = computed(() => {
      let tsDict = {}
      if (eventsByKind.value) {
        Object.keys(eventsByKind.value).forEach(kind => {
          eventsByKind.value[kind].forEach(event => {
            if (tsDict[Date.parse(event.timestamp)]) {
              tsDict[Date.parse(event.timestamp)][kind] = event
            } else {
              tsDict[Date.parse(event.timestamp)] = {}
              tsDict[Date.parse(event.timestamp)][kind] = event
            }
          })
        })
      }
      return tsDict
    })

    const sortedArrayOfTimestamps = computed(() => {
      let eventTimestamps = Object.keys(eventsByTimestamp.value)

      return eventTimestamps.sort(function (a, b) {
        return b - a
      })
    })

    onMounted(async () => {
      console.log('going to get chart events')
      await getChartEvents()
    })

    return {
      eventsByKind,
      metricsForThisPatient,
      datasets,
      eventsByTimestamp,
      sortedArrayOfTimestamps,
      //underchartDatasets,
      normalizeValue,
      specificRelativeTimeJustHoursAndMinutes,
      CONSTS,
      specificRelativeTimeJustDate,
      displayTab,
    }
  },
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 10.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.novo-sticky-header {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.card-shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03), 0 4px 4px rgba(0, 0, 0, 0.03),
    0 8px 8px rgba(0, 0, 0, 0.03), 0 16px 16px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.novo-tab {
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.4s;
}
</style>
