<template>
  <v-row xs="12" v-if="refType && (itemToDisplay || metricToDisplay)" no-gutters class="px-4">
    <v-card rounded class="pa-5 px-2 mb-1 flex" min-height="160" color="grey lighten-4" flat>
      <v-row class="fill-height" no-gutters align="center" justify="center" >
        <metric-thumbnail :showChart="false" :specificRange="true" v-if="metricToDisplay" :metricToDisplay="metricToDisplay"/>
        <v-card v-if="itemToDisplay" flat outlined style="border-radius: 6px; overflow:hidden; min-width: 300px;">
          <v-card v-if="itemToDisplay.media === 'condition' || itemToDisplay.media === 'medication'" tile class="pa-4" flat>
            <div v-if="itemToDisplay.media === 'condition'" >
              <div class="font-weight-bold text-body-1" >
                {{itemToDisplay.code}}
              </div>
              <div class="font-weight-light text-body-2" >
                {{itemToDisplay.name}}
              </div>
            </div>
            <div v-if="itemToDisplay.media === 'medication'">
              <div class="font-weight-bold text-body-1">
                {{itemToDisplay.drug}}
              </div>
              <div class="font-weight-light text-body-2" >
                {{itemToDisplay.strength}}
              </div>
            </div>
          </v-card>
        </v-card>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import MetricThumbnail from './MetricThumbnail.vue';
import consts from '@/consts'
import HelperMixin from '@/core/mixins/HelperMixin'
import { castMetricKind } from '@/helpers/metric'

export default {
  mixins: [HelperMixin],
  components: { MetricThumbnail },
  props: {
      item: null
  },
  data() {
    return {
      metricToDisplay: null,
      itemToDisplay: null
    }
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        this.setUpMetricThumbnail()
      },
    }
  },
  computed: {
    note() {
      return this.item?.fullNote
    },
    refData() {
      return this.note?.data?.referenceData?.data
    },
    refType() {
      return this.note?.data?.referenceData?.type
    }
  },
  methods: {
    setUpMetricThumbnail() {
      if (this.refType === 'metric') {
        const metric = this.refData
        let metricKind = metric.kind || metric.eventType // backward-forward-compat, fallback to eventType
        if (metricKind) {
          metricKind = castMetricKind(metricKind)
          const metricInformation = {...consts.METRICS[metricKind]}
          this.metricToDisplay = metricInformation
          this.metricToDisplay.latestEvent = this.refData
        } else {
          // that means its a really old metric event.... just ignore for now.
        }
      }

      if (this.refType === 'medication') {
        const noteObject = {}
        if (this.refData.fullItem) {
          noteObject.mediaCardColor = 'grey lighten-5'
          noteObject.media = this.refType
          if (noteObject.media === 'medication' && this.refData.fullItem) {
            noteObject.drug = this.refData.fullItem.split("|")[0]
            noteObject.strength = this.refData.fullItem.split("|")[1]
          }
        }
        this.itemToDisplay = noteObject
      }
            
      if (this.refType === 'condition') {
        const conditionInformation = this.refData.fullItem
        const noteObject = {}
        noteObject.mediaCardColor = 'grey lighten-5'
        noteObject.media = this.refType
        
        // there might be old data in here complicating things
        if (conditionInformation?.compound) {
          noteObject.code = this.refData.fullItem.compound.split("|")[0]
          noteObject.name = this.refData.fullItem.compound.split("|")[1]
          noteObject.mediaSingleLine = this.refData.fullItem.compound.split("|")[1]
        } else if (conditionInformation && conditionInformation.split('|')[0]) {
          noteObject.code = this.refData.fullItem.split("|")[0]
          noteObject.name = this.refData.fullItem.split("|")[1]
          noteObject.mediaSingleLine = this.refData.fullItem.split("|")[1]
        }

        this.itemToDisplay = noteObject
      }
    },
  },
  mounted() {
    this.setUpMetricThumbnail()
  }
}
</script>