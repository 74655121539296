<template>
  <v-card v-if="patientInfoForEditing" flat color="grey lighten-5" :class="inline ? null : 'flex mb-4 pb-4 px-3'">
    <v-select
      v-if="inline"
      @change="savePatientInfo"
      v-model="patientInfoForEditing.attributedProvider"
      :items="arrayOfProviders"
      hide-details
      dense
      placeholder=" Add attributed provider"
      reverse
      :append-icon="inline ? null : 'mdi-chevron-down'"
      solo
      flat
      background-color="transparent"
      :disabled="arrayOfProviders.length === 0"
    >
      <template v-slot:item="{ item }">
        {{ providersDict[item].displayName }}
      </template>
      <template v-slot:selection="{ item }">
        <span class="text-truncate" v-if="inline" style="font-weight: bold">{{ providersDict[item].displayName }}</span>
        <span v-else>{{ providersDict[item].displayName }}</span>
      </template>
    </v-select>

    <v-select
      v-else
      @change="savePatientInfo"
      v-model="patientInfoForEditing.attributedProvider"
      :items="arrayOfProviders"
      hide-details
      dense
      outlined
      label="Attributed provider"
      :append-icon="inline ? null : 'mdi-chevron-down'"
    >
      <template v-slot:item="{ item }">
        {{ providersDict[item].displayName }}
      </template>
      <template v-slot:selection="{ item }">
        <span class="text-truncate" v-if="inline" style="font-weight: bold; width: 140px">{{
          providersDict[item].displayName
        }}</span>
        <span v-else>{{ providersDict[item].displayName }}</span>
      </template>
    </v-select>
  </v-card>
</template>
<script>
import { defineComponent, onMounted, ref, computed, onBeforeUnmount } from '@vue/composition-api'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

export default defineComponent({
  props: {
    patient: {
      type: Object,
      default: () => {},
    },
    inline: false,
  },
  setup(props, context) {
    const rootStore = context.root.$store // TODO: move our modules from vuex to composition api
    const patientInfo = ref(props.patient.generalNotes)
    const edited = ref(false)

    const usersDict = ref(rootStore.state.org.usersDict)

    const providersDict = ref(rootStore.state.org.providers)

    const arrayOfProviders = computed(() => {
      return Object.keys(providersDict.value).sort((a, b) =>
        providersDict.value[a].lastName.localeCompare(providersDict.value[b].lastName)
      )
    })

    const autoGeneratedPatientInfoContent = computed(() => {
      return {
        generalNotes: '',
        lastRevised: '',
        lastAuthor: '',
        editLog: [],
        attributedProvider: null,
      }
    })

    const patientInfoForEditing = ref(null)

    const savePatientInfo = async () => {
      console.log('savinf patient info')
      patientInfoForEditing.value.updated = new Date().toISOString()
      patientInfoForEditing.value.lastAuthor = rootStore.state.auth.user.id
      patientInfoForEditing.value.editLog.push({
        author: rootStore.state.auth.user.id,
        revision: patientInfoForEditing.value.updated,
      })

      await rootStore
        .dispatch('patient/oneOffUpdateRemoteOnly', {
          patientId: props.patient.id,
          payload: { generalNotes: { value: JSON.stringify(patientInfoForEditing.value) } },
        })
        .catch(error => {
          console.log(error)
        })

      edited.value = false
    }

    onMounted(() => {
      if (props.patient.generalNotes) {
        patientInfoForEditing.value = JSON.parse(props.patient.generalNotes)
      } else {
        patientInfoForEditing.value = JSON.parse(JSON.stringify(autoGeneratedPatientInfoContent.value))
      }
    })

    onBeforeUnmount(() => {
      if (edited.value) {
        savePatientInfo()
      }
    })

    return {
      patientInfo,
      autoGeneratedPatientInfoContent,
      patientInfoForEditing,
      edited,
      specificRelativeTimeWithHoursAndMinutes,
      usersDict,
      savePatientInfo,
      arrayOfProviders,
      providersDict,
    }
  },
})
</script>

<style>
.card.left {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(-230px, -150px);
  z-index: 0;
}
.card {
  -webkit-transition: 0.3s linear;
  -moz-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
  width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1) translate(0px, 30px);
  z-index: 10;
}
.card.right {
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.35);
  -o-transform: scale(0.35);
  -ms-transform: scale(0.35);
  transform: scale(0.35) translate(230px, -150px);
  z-index: 0;
}
</style>
