<template>
  <v-card rounded="lg" flat class="flex pt-4 px-4 pb-4" outlined>
    <v-scale-transition>
      <v-tooltip bottom v-if="note.needsUserReview">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" style="margin-right: -28px" color="primary" absolute small right top fab
            ><v-icon>mdi-at</v-icon>
          </v-btn>
        </template>
        You've been tagged
      </v-tooltip>
    </v-scale-transition>

    <v-scale-transition>
      <v-tooltip bottom v-if="note.needsCareTeamReview">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" style="margin-right: -28px" color="primary" absolute small right top fab
            ><v-icon>mdi-flag</v-icon>
          </v-btn>
        </template>
        Needs care team review
      </v-tooltip>
    </v-scale-transition>

    <v-scale-transition>
      <v-tooltip bottom v-if="note.needsProviderReview">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            style="margin-right: -28px"
            color="grey lighten-4"
            absolute
            small
            right
            top
            fab
            ><v-icon color="red">mdi-flag</v-icon>
          </v-btn>
        </template>
        Waiting for provider review
      </v-tooltip>
    </v-scale-transition>

    <v-scale-transition>
      <v-tooltip bottom v-if="note.needsTicklerReview">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" style="margin-right: -28px" color="primary" absolute small right top fab dark
            ><v-icon>mdi-calendar</v-icon>
          </v-btn>
        </template>
        Flagged for follow up
      </v-tooltip>
    </v-scale-transition>

    <v-row align="center" no-gutters>
      <v-avatar size="40" class="mr-3" color="primary"
        ><span v-if="userDict[note.userId]" class="font-weight-bold white--text" style="font-size: 16px">{{
          userDict[note.userId].initials
        }}</span>
      </v-avatar>
      <v-col>
        <div v-if="userDict[note.userId]" style="font-weight: bold; margin-bottom: -2px">
          {{ userDict[note.userId].name }}
        </div>
        <div style="font-size: 14px; margin-top: -2px">
          {{ returnRelativeTime(note.ts) }}
        </div>
      </v-col>
      <!-- <v-avatar size="40" v-if="note.needsProviderReview" color="grey lighten-4">
        <v-icon color="red">mdi-flag</v-icon>
      </v-avatar> -->
    </v-row>

    <v-row class="py-2 text-body-1 mb-4" no-gutters>
      {{ note.text }}
    </v-row>
    <v-card flat rounded="lg" color="grey lighten-4">
      <v-row v-for="(comment, index) in noteComments" :key="index" class="pt-2 px-3" no-gutters>
        <v-col>
          <v-row class="text-subtitle-2 text--secondary" no-gutters>
            <span v-if="userDict[comment.userId]"
              >{{ userDict[comment.userId].name }}
              <span style="font-weight: 400; opacity: 0.8">{{ returnRelativeTime(comment.ts) }}</span></span
            >
          </v-row>
          <v-row class="text-body-2" no-gutters>
            {{ comment.text }}
          </v-row>
        </v-col>
      </v-row>
      <v-divider v-if="noteComments.length > 0" class="mt-3 mb-1 mx-2" />
      <v-row no-gutters>
        <v-col>
          <v-card flat rounded="lg" color="transparent">
            <v-textarea
              ref="newCommentTextInput"
              background-color="grey lighten-4"
              hide-details
              solo
              flat
              placeholder="Add a comment..."
              auto-grow
              :rows="1"
              row-height="12"
              v-model="newCommentText"
              :loading="savingNewComment"
              :read-only="savingNewComment"
              @keydown.enter.exact.stop.prevent=""
              @keyup.enter.exact.stop.prevent="handleNoteAction()"
              @focus="writingAComment = true"
              @blur="writingAComment = false"
            >
              <v-btn
                v-if="commentToSubmit"
                style="margin-top: -5px"
                @click="commentFlaggedForProviderReview = !commentFlaggedForProviderReview"
                icon
                slot="append"
                ><v-icon v-if="commentFlaggedForProviderReview" color="red">mdi-flag</v-icon
                ><v-icon v-else>mdi-flag-outline</v-icon></v-btn
              >
            </v-textarea>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-row
      v-if="note.needsCareTeamReview || note.needsUserReview || note.needsTicklerReview"
      align="center"
      class="mt-4"
      no-gutters
    >
      <v-spacer v-if="commentToSubmit" />

      <v-btn @click="handleNoteAction()" :class="commentToSubmit ? null : 'flex'" depressed color="primary">
        <span><span v-if="commentToSubmit">Post and </span><span>Mark as REVIEWED</span></span>
      </v-btn>
    </v-row>
    <v-expand-transition>
      <div v-if="!note.needsCareTeamReview && !note.needsUserReview && commentToSubmit">
        <v-row align="center" class="mt-4" no-gutters>
          <v-spacer v-if="commentToSubmit" />

          <v-btn @click="handleNoteAction()" :class="commentToSubmit ? null : 'flex'" depressed color="primary">
            <span>post</span>
          </v-btn>
        </v-row>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import dayjs from 'dayjs'
dayjs.extend(relativeTime)
import relativeTime from 'dayjs/plugin/relativeTime'
import { specificRelativeTimeWithHoursAndMinutes } from '@/helpers/time'

export default defineComponent({
  props: {
    patientId: null,
    needsCareTeamReview: false,
    note: {
      type: Object,
      default: () => {
        return {
          ts: 1643920402,
          userID: '12345768',
          text: 'This is a beautiful note regarding a patient',
          tags: ['Critical'],
          comments: [{ ts: 1643920405, userID: '1234ss5768', text: 'and Here is a comment! ' }],
        }
      },
    },
  },
  setup(props, context) {
    const writingAComment = ref(false)
    const rootStore = context.root.$store // TODO: move our modules from vuex to composition api
    const userInitials = ref('DB')
    const userName = ref('David Brodegard')
    const newCommentText = ref('')
    const newCommentTextInput = ref(null)
    const returnRelativeTime = ts => {
      let date = Date.parse(ts)
      return specificRelativeTimeWithHoursAndMinutes(date)
    }
    const originalAuthorInitials = computed(() => {
      return props.authorMode ? userInitials : props.note.userID
    })

    const commentFlaggedForProviderReview = ref(false)

    const commentToSubmit = computed(() => {
      if (
        writingAComment.value ||
        (newCommentText.value !== null && newCommentText.value !== '') ||
        savingNewComment.value
      ) {
        return true
      }
      return false
    })

    const working = ref(false)

    const handleNoteAction = async () => {
      working.value = true

      if (commentToSubmit.value) {
        await saveNewComment()
        working.value = false
      } else {
        console.log(props.note.notificationForEditing)
        let mentionForClearing
        let careTeamReviewForClearing
        let ticklerForClearing

        if (props.note.needsCareTeamReview) {
          careTeamReviewForClearing = props.note.notificationForEditing
        }

        if (props.note.needsUserReview) {
          mentionForClearing = props.note.notificationForEditing
        }

        if (props.note.needsTicklerReview) {
          ticklerForClearing = props.note.notificationForEditing
        }

        await rootStore.dispatch('patient/addNoteComment', {
          noteUserId: props.note.userId,
          noteTs: props.note.ts,
          text: 'Reviewed',
          providerReview: false,
          dismissMention: mentionForClearing,
          dismissCareTeamReview: careTeamReviewForClearing,
          dismissTickler: ticklerForClearing,
        })
        working.value = false
      }
    }

    const savingNewComment = ref(false)
    const saveNewComment = async () => {
      newCommentText.value = newCommentText.value.trim()
      if (newCommentText.value === '') return
      // 1. Add comment on note.
      savingNewComment.value = true
      let mentionForClearing
      let careTeamReviewForClearing

      if (props.note.needsCareTeamReview) {
        careTeamReviewForClearing = props.note.notificationForEditing
      }

      if (props.note.needsUserReview) {
        mentionForClearing = props.note.notificationForEditing
      }
      await rootStore.dispatch('patient/addNoteComment', {
        noteUserId: props.note.userId,
        noteTs: props.note.ts,
        text: newCommentText.value,
        providerReview: commentFlaggedForProviderReview.value,
        dismissMention: mentionForClearing,
        dismissCareTeamReview: careTeamReviewForClearing,
      })
      newCommentText.value = ''
      savingNewComment.value = false
    }

    const noteComments = computed(() => {
      return props.note.comments.sort(function (a, b) {
        return new Date(a.ts).getTime() - new Date(b.ts).getTime()
      })
    })

    const userDict = computed(() => {
      const dict = {}
      for (const uid in rootStore.state.org.usersDict) {
        const user = rootStore.state.org.usersDict[uid]
        const u = { name: 'Unknown', initials: 'UK' }
        if (user.firstName && user.lastName) {
          u.name = user.firstName + ' ' + user.lastName
          u.initials = user.firstName.charAt(0) + user.lastName.charAt(0)
        }
        dict[uid] = u
      }
      return dict
    })

    return {
      originalAuthorInitials,
      userName,
      saveNewComment,
      savingNewComment,
      newCommentText,
      newCommentTextInput,
      noteComments,
      returnRelativeTime,
      userDict,
      commentFlaggedForProviderReview,
      writingAComment,
      commentToSubmit,
      handleNoteAction,
    }
  },
})
</script>
