<template>
  <v-sheet color="transparent" flat class="flex py-4 pb-0">
    <v-card rounded="lg" class="pa-3 flex" flat color="grey lighten-4" @click="createNewNote">
      <span style="opacity: 0.5">Add a note...</span>
    </v-card>

    <v-row :key="redrawNotes" v-if="preparedNotes.length > 0" class="py-4" no-gutters>
      <v-col>
        <patient-note-card class="mb-4" v-for="(note, index) in preparedNotes" :key="index" :note="note" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import PatientNoteCard from './PatientNoteCard.vue'
import bus from '@/core/helpers/bus'
import { mapState } from 'vuex'

export default {
  components: {
    PatientNoteCard,
  },
  data() {
    return { redrawNotes: 0 }
  },
  watch: {
    providerReviewNotifications: {
      handler() {
        console.log('redraw notes')
        this.redrawNotes = this.redrawNotes + 1
      },
      deep: true,
    },
    mentionsTimestampDict: {
      handler() {
        console.log('mentionsDict Updated')
        this.redrawNotes = this.redrawNotes + 1
      },
      deep: true,
    },
    careTeamReviewNotifications: {
      handler() {
        this.redrawNotes = this.redrawNotes + 1
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('org', ['providerReviewNotifications', 'careTeamReviewNotifications', 'mentions', 'ticklers']),
    ...mapState('patient', ['patient']),
    providerReviewTimestampsDict() {
      let notesForCurrentPatient = this.providerReviewNotifications.filter(e => e.patient === this.patient.id)
      let timestampDict = {}
      notesForCurrentPatient.forEach(note => {
        timestampDict[note.ts.toString()] = note
      })

      return timestampDict
    },
    careTeamReviewTimestampsDict() {
      let notesForCurrentPatient = this.careTeamReviewNotifications.filter(e => e.patient === this.patient.id)
      let timestampDict = {}
      notesForCurrentPatient.forEach(note => {
        timestampDict[note.ts.toString()] = note
      })

      return timestampDict
    },
    mentionsTimestampDict() {
      let notesForCurrentPatient = this.mentions.filter(e => e.patient === this.patient.id)
      let timestampDict = {}
      notesForCurrentPatient.forEach(note => {
        timestampDict[note.ts.toString()] = note
      })

      return timestampDict
    },
    ticklersTimestampDict() {
      let notesForCurrentPatient = this.ticklers.filter(e => e.patient === this.patient.id)
      let timestampDict = {}
      notesForCurrentPatient.forEach(note => {
        timestampDict[note.ts.toString()] = note
      })

      return timestampDict
    },

    preparedNotes() {
      let notes = []
      this.patient.rpm.notes.forEach(note => {
        note.needsCareTeamReview = false
        note.needsProviderReview = false
        note.needsUserReview = false
        if (this.careTeamReviewTimestampsDict[note.ts]) {
          note.needsCareTeamReview = true
          note.notificationForEditing = this.careTeamReviewTimestampsDict[note.ts]
        }

        if (this.providerReviewTimestampsDict[note.ts]) {
          note.needsProviderReview = true
          note.notificationForEditing = this.providerReviewTimestampsDict[note.ts]
        }

        if (this.mentionsTimestampDict[note.ts]) {
          note.needsUserReview = true
          note.notificationForEditing = this.mentionsTimestampDict[note.ts]
        }

        if (this.ticklersTimestampDict[note.ts]) {
          note.needsTicklerReview = true
          note.notificationForEditing = this.ticklersTimestampDict[note.ts]
        }

        notes.push(note)
      })
      return notes
    },
  },
  methods: {
    createNewNote() {
      bus.$emit('newNote')
    },
  },
}
</script>
