<template>
  <v-container class="pa-0" fluid>
    <patient-combined-charts-context-menu
      :showMenu="hoverPoint.show"
      :positionX="hoverPoint.x"
      :positionY="hoverPoint.y"
      :metricToDisplay="hoverPoint.payload"
      :color="hoverPoint.color"
    />
    <v-row justify="start" dense>
      <v-col
        v-for="(kind, index) in kinds"
        :key="index"
        cols="12"
        :md="Math.floor(12 / kinds.length) < 4 ? 3 : Math.floor(12 / kinds.length)"
        :lg="Math.floor(12 / kinds.length) < 4 ? 3 : Math.floor(12 / kinds.length)"
        :xl="Math.floor(12 / kinds.length) < 4 ? 3 : Math.floor(12 / kinds.length)"
      >
        <patient-combined-charts-thumbnail
          @toggle="toggleMetricKind(kind)"
          :metricToDisplay="yearOfMetrics[kind][0]"
          :color="colorSet[kind].card"
        ></patient-combined-charts-thumbnail>
      </v-col>
    </v-row>
    <v-card rounded="lg" class="mt-4 pa-4" outlined>
      <v-row align="center" no-gutters>
        <span class="text-h6 font-weight-bold">
          {{ labelForRange }}
        </span>
        <v-spacer />
        <v-btn depressed small class="mr-1" @click="snapTo365()">1 Yr</v-btn>
        <v-btn depressed small class="mr-1" @click="snapTo90()">90 Day</v-btn>
        <v-btn depressed small class="mr-1" @click="snapTo30()">30 Day</v-btn>
        <v-btn depressed small class="mr-1" @click="snapTo7()">7 Day</v-btn>
        <v-btn v-show="showResetZoom && false" depressed small class="mr-1" @click="resetZoom()">Reset Zoom</v-btn>
        <portal-target name="chartInstructions" slim />
      </v-row>

      <v-hover v-slot="{ hover }">
        <v-card rounded="lg" flat>
          <v-fab-transition>
            <v-btn
              v-show="showBack && (hover || isMobileAppleDevice)"
              fab
              dark
              small
              style="position: absolute; left: -18px; top: 130px; z-index: 10"
              color="primary"
              @click="scootBack()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-fab-transition>
            <v-btn
              v-show="showForward && (hover || isMobileAppleDevice)"
              fab
              dark
              small
              style="position: absolute; right: -18px; top: 130px; z-index: 10"
              color="primary"
              @click="scootForward()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-fab-transition>

          <vue-apex-charts
            v-if="yearOfMetricsLoaded"
            class="mainChart"
            v-bind:class="hoverPoint.show ? 'pointerClass' : 'zoomClass'"
            ref="chartObject"
            :options="chartOptions"
            height="300"
            type="line"
            :series="chartSeries"
          ></vue-apex-charts>
          <v-card flat height="200" v-else>
            <v-row no-gutters class="fill-height" align="center" justify="center">
              <v-progress-circular color="primary" indeterminate />
            </v-row>
          </v-card>
        </v-card>
      </v-hover>
      <v-row no-gutters>
        <v-expand-transition>
          <v-card flat rounded="lg" class="flex pt-0">
            <v-divider />
            <v-simple-table height="230" fixed-header dense style="width: 100%; background-color: transparent">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Time</th>
                    <th v-for="(kind, index) in kinds" :key="index" class="text-center">
                      {{ METRICS[kind].abbreviation }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(listRow, index) in chartList"
                    v-bind:class="hoverPoint.show && hoverPoint.tsMs === listRow.tsMs ? 'hoveredValue' : null"
                    :key="index"
                  >
                    <td style="max-width: 220px; width: 220px; text-align: left">
                      {{ relativeTime(listRow.tsMs) }}
                    </td>
                    <td v-for="(kind, index) in kinds" :key="index" class="text-center">
                      <span v-if="listRow.data[kind]" class="">
                        {{ listRow.data[kind].displayValue
                        }}<span class="font-weight-light ml-1">{{ listRow.data[kind].units }}</span>
                      </span>
                      <v-btn
                        @click="showPDF(listRow.data[kind])"
                        small
                        v-show="kind === 'Ecg' && listRow.data[kind]"
                        class="ml-1"
                        color="primary"
                        icon
                        ><v-icon>mdi-pdf-box</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-expand-transition>
      </v-row>
    </v-card>
    <portal to="patientFeedFirstRightPanel">
      <v-card outlined class="rightBar pt-2 pr-4" color="transparent">
        <v-row v-show="false" no-gutters>
          <span class="text-body-2 font-weight-medium text--secondary"> Patient Activity </span>
        </v-row>

        <v-row no-gutters>
          <span class="text-body-2 font-weight-medium text--secondary">
            {{ getMonthAndYearFromTimestamp(new Date().getTime()) }} Chart Time
          </span>
        </v-row>
        <v-row align="center" no-gutters class="px-0 pt-3">
          <span class="text-body-2">{{ msToTime2(patient.millisecondsThisPeriod) }} </span>
        </v-row>
        <v-row class="pt-6" no-gutters>
          <span class="text-body-2 font-weight-medium text--secondary"> Current RPM Period </span>
        </v-row>
        <v-row align="center" no-gutters class="px-0 pt-3">
          <span class="text-body-2 mr-4"> {{ currentPeriodStart }} </span><v-divider /><span class="text-body-2 ml-4">
            {{ currentPeriodEnd }}
          </span>
        </v-row>

        <v-row align="center" justify="space-between" no-gutters class="pt-6">
          <span class="text-body-2 font-weight-medium text--secondary"> Statistics </span>
          <v-btn
            depressed
            v-bind:color="thirtyStats ? 'primary' : 'white'"
            @click=";(thirtyStats = true), (sixtyStats = false), (ninetyStats = false)"
            x-small
            >30</v-btn
          >
          <v-btn
            @click=";(sixtyStats = true), (thirtyStats = false), (ninetyStats = false)"
            x-small
            depressed
            v-bind:color="sixtyStats ? 'primary' : 'white'"
            >60</v-btn
          >
          <v-btn
            @click=";(ninetyStats = true), (sixtyStats = false), (thirtyStats = false)"
            x-small
            depressed
            v-bind:color="ninetyStats ? 'primary' : 'white'"
            >90</v-btn
          >
        </v-row>

        <v-row v-for="(item, index) in statistics" :key="index" no-gutters class="pt-3">
          <v-card v-show="item.name !== 'ECG'" flat class="flex text-body-2" color="transparent" no-gutters>
            <v-row class="font-weight-bold" no-gutters>
              {{ item.name }}
            </v-row>
            <v-row v-show="thirtyStats" justify="space-between" no-gutters
              ><span>30 day high</span>{{ item.thirty.high }}
            </v-row>
            <v-row v-show="thirtyStats" justify="space-between" no-gutters
              ><span>30 day low</span>{{ item.thirty.low }}
            </v-row>
            <v-row v-show="thirtyStats" justify="space-between" no-gutters
              ><span>30 day avg</span>{{ item.thirty.avg }}
            </v-row>
            <v-row v-show="sixtyStats" justify="space-between" no-gutters
              ><span>60 day high</span>{{ item.sixty.high }}
            </v-row>
            <v-row v-show="sixtyStats" justify="space-between" no-gutters
              ><span>60 day low</span>{{ item.sixty.low }}
            </v-row>
            <v-row v-show="sixtyStats" justify="space-between" no-gutters
              ><span>60 day avg</span>{{ item.sixty.avg }}
            </v-row>
            <v-row v-show="ninetyStats" justify="space-between" no-gutters
              ><span>90 day high</span>{{ item.ninety.high }}
            </v-row>
            <v-row v-show="ninetyStats" justify="space-between" no-gutters
              ><span>90 day low</span>{{ item.ninety.low }}
            </v-row>
            <v-row v-show="ninetyStats" justify="space-between" no-gutters
              ><span>90 day avg</span>{{ item.ninety.avg }}
            </v-row>
          </v-card>
        </v-row>
      </v-card>
    </portal>

    <v-row justify="center">
      <v-dialog v-model="ecgDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card flat color="green">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="ecgDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>ECG</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="ecgDialog = false"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <iframe
            frameborder="0"
            style="overflow: hidden; height: 100%; width: 100%"
            height="500px"
            width="100%"
            :src="dialogURL"
          ></iframe>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.mainChart {
  .yAxisLabel {
    background-color: green !important;
    border: 1px solid blue !important;
  }
}
.hoveredValue {
  background-color: #f5f5f5;
  border: 1px solid green !important;
}
.pointerClass {
  cursor: pointer;
}
.zoomClass {
  cursor: crosshair;
}
.arrow_box {
  background-color: green;
  padding: 6px;
}
.v-icon.active {
  transform: rotate(-180deg);
}
</style>

<script>
import { mapState } from 'vuex'
import { PDFDocument } from 'pdf-lib'

import HelperMixin from '@/core/mixins/HelperMixin'
import VueApexCharts from 'vue-apexcharts'
import PatientCombinedChartsThumbnail from '../metriccharts/PatientCombinedChartsThumbnail.vue'

import dayjs from 'dayjs'
import PatientCombinedChartsContextMenu from '../metriccharts/PatientCombinedChartsContextMenu.vue'
import bus from '@/core/helpers/bus'
import moment from 'moment-timezone'
import consts from '@/consts'
import { msToTime2 } from '@/helpers/time'

const excludeKinds = { BodyTemp: true, CovidRisk: true, Pain: true }

export default {
  mixins: [HelperMixin],
  components: {
    VueApexCharts,
    PatientCombinedChartsThumbnail,
    PatientCombinedChartsContextMenu,
  },
  data() {
    return {
      thirtyStats: true,
      sixtyStats: false,
      ninetyStats: false,
      hoverPoint: { show: false, point: null, x: null, y: null },
      chartRange: 7,
      chartStartOffset: 1,
      labelForMaxY: '100',
      labelForRange: 'Jul 30 - Aug 6',
      chartXMin: 0,
      chartXMax: 0,
      ecgDialog: false,
      dialogURL:
        'https://storage.googleapis.com/nv_public/ecg/da736bf6d6ebe355b15c3eb88c08301c8576c36775cb167a5289aa09a9beabcf.raw',
      msToTime2,
      colorSet,
      METRICS: consts.METRICS,
      hiddenMetricKinds: {},
    }
  },
  computed: {
    ...mapState('patient', ['patient', 'yearOfMetricsLoaded']),
    ...mapState('patient', { patientYearOfMetrics: 'yearOfMetrics' }),
    yearOfMetrics() {
      const yearOfMetrics = {}
      if (!this.yearOfMetricsLoaded) {
        return yearOfMetrics // don't load stuff until metrics are loaded.
      }
      for (const kind in this.patientYearOfMetrics) {
        if (excludeKinds[kind]) {
          continue
        }
        const metrics = this.patientYearOfMetrics[kind]
        if (!metrics || metrics.length === 0) {
          continue
        }
        yearOfMetrics[kind] = metrics
      }
      return yearOfMetrics
    },
    kinds() {
      const kinds = Object.keys(this.yearOfMetrics)
      kinds.sort()
      return kinds
    },
    // chartSeries turns the last yearOfMetrics into XY apexcharts data series
    // [{ name: <kind>, kind: <kind>, data: [{x: <metric.tsMs>, y: <metric.graphValue>, metric: <metric>, alert: <metric.alert> }] }]
    chartSeries() {
      const series = []
      for (const kind of this.kinds) {
        if (this.yearOfMetrics[kind].length === 0) {
          continue // shouldn't happen, but in case of no data for this metric kind, skip
        }

        // Special handling for blood pressure
        if (kind == 'BloodPressure') {
          const systolicSeries = { name: 'BloodPressure_SYSTOLIC', data: [], kind }
          const diastolicSeries = { name: 'BloodPressure_DIASTOLIC', data: [], kind }
          for (const metric of this.yearOfMetrics[kind]) {
            systolicSeries.data.push({
              x: metric.tsMs,
              y: metric.data.systolicValue,
              metric: metric,
              alert: metric.alert,
            })
            diastolicSeries.data.push({
              x: metric.tsMs,
              y: metric.data.diastolicValue,
              metric: metric,
              alert: metric.alert,
            })
          }
          series.push(systolicSeries)
          series.push(diastolicSeries)
          continue
        }

        const kindSeries = { name: kind, data: [], kind }
        for (const metric of this.yearOfMetrics[kind]) {
          kindSeries.data.push({ x: metric.tsMs, y: metric.graphValue, metric: metric, alert: metric.alert })
        }
        series.push(kindSeries)
      }
      return series
    },
    // chartList is a time-descending list of metric bundles. Metrics that occurred at the same time are bundled.
    // chartList shows the itemized metrics that occurred within the selected time range on the metrics chart.
    // { tsMs: <tsMs>, data: {<kind>: <metric>}}
    chartList() {
      const arrayOfValues = []
      const tsMsToMetricsMap = new Map() // {tsMs -> { kind -> metric }}, this bundles metrics at the same timestamp together
      for (const kind in this.yearOfMetrics) {
        for (const metric of this.yearOfMetrics[kind]) {
          if (!tsMsToMetricsMap.has(metric.tsMs)) {
            tsMsToMetricsMap.set(metric.tsMs, {})
          }
          tsMsToMetricsMap.get(metric.tsMs)[kind] = metric
        }
      }
      const sortedTsMs = [...tsMsToMetricsMap.keys()].sort((a, b) => {
        return b - a
      }) // sort time-descending
      for (const tsMs of sortedTsMs) {
        if (tsMs < this.chartXMin || tsMs > this.chartXMax) {
          continue // only show data between the visible start and end
        }
        arrayOfValues.push({ tsMs: tsMs, data: tsMsToMetricsMap.get(tsMs) })
      }
      return arrayOfValues
    },
    isMobileAppleDevice() {
      if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        return true
      }
      return false
    },
    showForward() {
      return this.chartXMax < new Date().getTime()
    },
    showBack() {
      if (this.chartStartOffset * this.chartRange < 360) {
        return true
      }
      return false
    },
    showResetZoom() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      let normalRange = chartEnd.getTime() - chartStart.getTime()

      let currentRange = this.chartXMax - this.chartXMin

      return currentRange < normalRange
    },
    currentPeriodStart() {
      if (this.patient.rpm?.enrollment?.firstData) {
        // this tells me when the next 99454 is up

        let periodStart = new Date(this.patient.rpm.enrollment.firstData)
        periodStart.setHours(0, 0, 0, 0)

        let rightNow = new Date()

        let monitoringPeriods = []

        while (periodStart.getTime() < rightNow.getTime()) {
          // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
          let start = periodStart.getTime()
          periodStart.setDate(periodStart.getDate() + 30)
          let end = new Date(periodStart.getTime() - 1).getTime()
          monitoringPeriods.push({ start: start, end: end })
        }

        let startDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].start)

        return dayjs(startDateObject.getTime()).format('MMM DD')

        // what if periods were 30 days from the start of the month?
        // what about feb?
      }

      return 'Not Enrolled'
    },
    currentPeriodEnd() {
      if (this.patient.rpm?.enrollment?.firstData) {
        // this tells me when the next 99454 is up

        let periodStart = new Date(this.patient.rpm.enrollment.firstData)
        periodStart.setHours(0, 0, 0, 0)

        let rightNow = new Date()

        let monitoringPeriods = []
        while (periodStart.getTime() < rightNow.getTime()) {
          // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
          let start = periodStart.getTime()
          periodStart.setDate(periodStart.getDate() + 30)
          let end = new Date(periodStart.getTime() - 1).getTime()
          monitoringPeriods.push({ start: start, end: end })
        }

        let endDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].end)

        return dayjs(endDateObject.getTime()).format('MMM DD')

        // what if periods were 30 days from the start of the month?
        // what about feb?
      }
      return 'Not Enrolled'
    },
    statistics() {
      const thirtyDays = new Date()
      thirtyDays.setHours(0, 0, 0, 0)
      thirtyDays.setDate(thirtyDays.getDate() - 31)

      const sixtyDays = new Date()
      sixtyDays.setHours(0, 0, 0, 0)
      sixtyDays.setDate(sixtyDays.getDate() - 61)

      const ninetyDays = new Date()
      ninetyDays.setHours(0, 0, 0, 0)
      ninetyDays.setDate(ninetyDays.getDate() - 91)

      const ranges = [
        { date: thirtyDays, name: 'thirty', ms: thirtyDays.getTime() },
        { date: sixtyDays, name: 'sixty', ms: sixtyDays.getTime() },
        { date: ninetyDays, name: 'ninety', ms: ninetyDays.getTime() },
      ]

      const statsToReturn = []
      for (const kind of this.kinds) {
        const metrics = this.yearOfMetrics[kind]
        const kindStats = {}
        if (consts.METRICS[kind].commonName) {
          kindStats.name = consts.METRICS[kind].commonName
        }
        statsToReturn.push(kindStats)

        for (const range of ranges) {
          const kindRangeStats = { name: range.name, high: '-', low: '-', avg: '-' }
          kindStats[range.name] = kindRangeStats

          let high = -Infinity,
            low = Infinity,
            numInRange = 0,
            total = 0,
            diastolicTotal = 0,
            highIndex,
            lowIndex
          for (let i = 0; i < metrics.length && metrics[i].tsMs > range.ms; i++) {
            numInRange++
            const r = metrics[i]
            let value
            if (kind === 'BloodPressure') {
              value = r.data.systolicValue
              total += value
              diastolicTotal += r.data.diastolicValue
            } else {
              value = r.graphValue
              total += value
            }
            if (value > high) {
              high = value
              highIndex = i
            }
            if (value < low) {
              low = value
              lowIndex = i
            }
          }
          if (numInRange === 0) {
            continue // no data within range
          }

          if (kind === 'BloodPressure') {
            kindRangeStats.high = metrics[highIndex].data.systolicValue + '/' + metrics[highIndex].data.diastolicValue
            kindRangeStats.low = metrics[lowIndex].data.systolicValue + '/' + metrics[lowIndex].data.diastolicValue
            kindRangeStats.avg = Math.round(total / numInRange) + '/' + Math.round(diastolicTotal / numInRange)
          } else {
            kindRangeStats.high = high
            kindRangeStats.low = low
            kindRangeStats.avg = Math.round((10 * total) / numInRange) / 10
          }
        }
      }
      return statsToReturn
    },
    chartMaxY() {
      let yMax = 0

      for (const s of this.chartSeries) {
        const localMax = Math.max.apply(
          Math,
          s.data.map(function (o) {
            return o.y
          })
        )
        if (localMax > yMax) {
          yMax = localMax
        }
      }

      return yMax
    },
    chartOptions() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEndDate = new Date()
      chartEndDate.setHours(23, 59, 59, 999)
      chartEndDate.setDate(chartEndDate.getDate())

      let chartStartTimestamp = chartStart.getTime()
      let chartEndTimestamp = chartEndDate.getTime()

      // color array

      let discreetMarkers = []
      let seriesIndex = 0
      for (const s of this.chartSeries) {
        let dataIndex = 0

        for (const data of s.data) {
          if (data.alert) {
            let marker = {
              seriesIndex: seriesIndex,
              dataPointIndex: dataIndex,

              fillColor: '#FFED3A',
              strokeColor: '#333333',
              size: 4,
              strokeWidth: 2,
              hover: {
                sizeOffset: 4,
              },
            }

            discreetMarkers.push(marker)
          }
          dataIndex++
        }
        seriesIndex++
      }

      let colorArray = []

      for (const s of this.chartSeries) {
        colorArray.push(colorSet[s.kind].card)
      }
      const vm = this
      return {
        legend: {
          show: false,
        },
        chart: {
          zoom: {
            enabled: true,
            type: 'x',
          },
          toolbar: {
            show: false,
          },

          id: toString(new Date().getTime()),

          sparkline: { enabled: false },
          animations: { enabled: false },
          events: {
            mounted: function (chartContext, config) {
              vm.chartXMin = config.globals.minX
              vm.chartXMax = config.globals.maxX

              let startText = moment(config.globals.minX).tz(moment.tz.guess()).format('MMM DD')

              let endText = moment(config.globals.maxX).tz(moment.tz.guess()).format('MMM DD')

              let momentOfToday = moment(new Date().getTime()).tz(moment.tz.guess()).format('MMM DD')

              if (momentOfToday === startText) {
                // the chart starts on today.
                startText = 'Today'
                endText = ''
              }

              if (momentOfToday === endText) {
                // the chart ends on today.

                endText = 'Today'
              }

              if (startText !== '' && endText !== '') {
                startText = startText + ' - '
              }

              if (startText === endText) {
                startText = ''
              }
              vm.labelForRange = startText + endText
              vm.labelForMaxY = config.globals.maxY
            },
            zoomed: function (chartContext, { xaxis }) {
              vm.chartXMin = xaxis.min
              vm.chartXMax = xaxis.max
              let startText = moment(xaxis.min).tz(moment.tz.guess()).format('MMM DD')

              let endText = moment(xaxis.max).tz(moment.tz.guess()).format('MMM DD')

              let momentOfToday = moment(new Date().getTime()).tz(moment.tz.guess()).format('MMM DD')

              if (momentOfToday === startText) {
                // the chart starts on today.
                startText = 'Today'
                endText = ''
              }

              if (momentOfToday === endText) {
                // the chart ends on today.

                endText = 'Today'
              }

              if (startText === endText) {
                startText = ''
              }

              if (startText !== '' && endText !== '') {
                startText = startText + ' - '
              }

              vm.labelForRange = startText + endText
            },

            dataPointSelection: (_, __, config) => {
              bus.$emit('newNote', {
                type: 'metric',
                data: vm.chartSeries[config.seriesIndex].data[config.dataPointIndex].metric,
              })
            },

            dataPointMouseEnter: function (event, chartContext, config) {
              vm.hoverPoint = {
                show: true,
                point: {
                  series: config.seriesIndex,
                  value: config.dataPointIndex,
                },
                payload: vm.chartSeries[config.seriesIndex].data[config.dataPointIndex].metric,
                color: colorSet[vm.chartSeries[config.seriesIndex].data[config.dataPointIndex].metric.kind].card,
                x: event.x + 15,
                y: event.y + 15,
                tsMs: vm.chartSeries[config.seriesIndex].data[config.dataPointIndex].metric.tsMs,
              }
            },
            dataPointMouseLeave: function () {
              vm.hoverPoint = {
                show: false,
                point: null,
                payload: null,
                x: null,
                y: null,
              }
            },
          },
        },
        colors: colorArray,
        stroke: { curve: 'smooth', colors: colorArray, width: 2 },
        fill: { opacity: 1, type: 'solid' },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return vm.returnCustomTooltip({
              series,
              seriesIndex,
              dataPointIndex,
              w,
            })
          },
          marker: {
            show: false,
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
          intersect: true,
          shared: false,
          enabled: true,
          x: {
            show: true,
          },
          y: {
            show: false,
          },
        },
        xaxis: {
          floating: false,
          type: 'datetime',
          max: chartEndTimestamp,
          min: chartStartTimestamp,
          crosshairs: {
            show: true,
            with: 20,
          },
          forceNiceScale: true,
          labels: {
            datetimeUTC: false,
            show: true,
            rotate: 0,
            maxHeight: 30,
            // formatter: function(timestamp) {
            //   // is this the first one of the day/month/year?

            //   return moment(timestamp)
            //     .tz(moment.tz.guess())
            //     .format('h:mm a')
            // },
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM d',
              day: 'MMM d',
              hour: 'h:mm tt',
              minute: 'h:mm tt',
            },
            style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Source Sans Pro, Helvetica, Arial, sans-serif',
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            show: true,
            align: 'left',
            minWidth: 40,
            maxWidth: 160,
            style: {
              colors: ['#757575'],
              fontSize: '15px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: -15,
            offsetY: 3,
            rotate: 0,
            formatter: function (val, index) {
              if (index === 0) {
                return ''
              }

              if (val > vm.chartMaxY + vm.chartMaxY * 0.2) {
                return val.toFixed(0)
              }
              return ''
            },
          },
          min: 0,
          max: this.chartMaxY + this.chartMaxY * 0.2,
        },
        markers: {
          size: 4,
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: '#ffffff',
          hover: {
            sizeOffset: 4,
          },
          discrete: discreetMarkers,
        },
        grid: {
          padding: { left: -40, right: 0 },
          show: true,
          position: 'back',
          row: {
            colors: ['#ffffff', '#ffffff'],
            opacity: 0.1,
          },
          column: {
            colors: ['#4485ed', '#ffffff'],
            opacity: 0.1,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      }
    },
  },
  methods: {
    toggleMetricKind(kind) {
      this.hiddenMetricKinds[kind] = !(this.hiddenMetricKinds[kind] || false)
      if (kind === 'BloodPressure') {
        this.$refs.chartObject.toggleSeries('BloodPressure_SYSTOLIC')
        this.$refs.chartObject.toggleSeries('BloodPressure_DIASTOLIC')
      } else {
        this.$refs.chartObject.toggleSeries(kind)
      }
    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (Math.floor(days) < 1) {
        let dt = moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value).tz(moment.tz.guess()).calendar()
        }
      } else {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
    },
    getPdfUrl(byte) {
      var blob = new Blob([byte], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      return url
    },
    async showPDF(metricData) {
      try {
        // fetch the pdf from the server
        let response = await fetch(metricData.data.pdfUrl)
        const pdf = await response.arrayBuffer()
        // load the pdf
        const pdfDoc = await PDFDocument.load(pdf)
        // add the patient for each page
        const pages = pdfDoc.getPages()
        const fontSize = 16
        const patientText = `${this.patient.lastName.toUpperCase() || ''} ID:${
          this.patient.org.patientId || 'not found'
        }`
        pages.forEach(page => {
          const { width, height } = page.getSize()
          page.drawText(patientText, {
            x: width - patientText.length * (fontSize / 2) - 30,
            y: height - fontSize * 1.5,
            size: fontSize,
          })
        })
        const pdfBytes = await pdfDoc.save()
        const pdfUrl = this.getPdfUrl(pdfBytes)
        // open the new pdf with patient info
        window.open(pdfUrl, 'ECG', 'width=500,height=960, toolbar=no,scrollbars=no,resizable=no,top=100,left=300')
        //this.ecgDialog = true
      } catch (e) {
        // just open the provided url if something fails adding the patient info
        window.open(
          metricData.data.pdfUrl,
          'ECG',
          'width=500,height=960, toolbar=no,scrollbars=no,resizable=no,top=100,left=300'
        )
      }
    },
    returnCustomTooltip({ series, seriesIndex, dataPointIndex }) {
      return (
        '<div style="display:none" class="arrow_box">' +
        '<span>' +
        series[seriesIndex][dataPointIndex] +
        '</span>' +
        '</div>'
      )
    },
    snapTo30() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 30)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },
    snapTo90() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 90)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },
    snapTo365() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 365)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },
    snapTo7() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      this.$refs.chartObject.zoomX(chartStart.getTime(), chartEnd.getTime())
    },

    snapTo1() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 0)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      //let length = chartEnd.getTime() - chartStart.getTime()

      let offset = 0

      this.$refs.chartObject.zoomX(chartStart.getTime() - offset, chartEnd.getTime() + offset)
    },
    resetZoom() {
      let chartStart = new Date()
      chartStart.setHours(0, 0, 0, 0)
      chartStart.setDate(chartStart.getDate() - 7)

      let chartEnd = new Date()
      chartEnd.setHours(23, 59, 59, 999)

      let normalRange = chartEnd.getTime() - chartStart.getTime()

      let currentRange = this.chartXMax - this.chartXMin

      let rangeDifference = normalRange - currentRange

      let rangeDifferenceOffset = rangeDifference / 2

      if (this.chartXMax + rangeDifferenceOffset > chartEnd.getTime()) {
        this.snapTo7()
      } else {
        this.$refs.chartObject.zoomX(this.chartXMin - rangeDifferenceOffset, this.chartXMax + rangeDifferenceOffset)
      }
    },
    scootBack() {
      let range = this.chartXMax - this.chartXMin

      let start = this.chartXMin - range

      let end = start + range

      this.$refs.chartObject.zoomX(start, end)
    },
    scootForward() {
      let range = this.chartXMax - this.chartXMin

      let start = this.chartXMin + range

      let end = start + range

      this.$refs.chartObject.zoomX(start, end)
    },
  },
}

const colorSet = {
  BloodPressure: {
    card: '#27A0FC',
    stroke: '#ffffff',
    dark: true,
    icon: 'mdi-heart',
  },
  BloodOxygen: {
    card: '#27A0FC',
    stroke: '#ffffff',
    dark: true,
    icon: 'mdi-heart',
  },
  BodyWeight: {
    card: '#375094',
    stroke: '#ffffff',
    dark: true,
    icon: 'mdi-scale-bathroom',
  },
  BloodGlucose: {
    card: '#00C0AC',
    stroke: '#ffffff',
    dark: true,
    icon: 'mdi-water',
  },
  Pulse: {
    card: '#EE1C24',
    stroke: '#ffffff',
    dark: true,
    icon: 'mdi-heart-pulse',
  },
  Ecg: {
    card: '#000000',
    stroke: '#ffffff',
    dark: true,
    icon: 'mdi-heart-pulse',
  },
}
</script>
