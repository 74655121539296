<template>
  <v-card @click="$emit('toggle'), hidden = !hidden" rounded="lg" style="overflow: hidden" outlined>
    <v-row no-gutters>
      <v-card flat rounded="lg" class="px-3 pl-5 pt-2 pb-2 flex" style="overflow: hidden">
        <v-card
          flat
          style="position: absolute; top: 7%; left: 6px; border-radius:3px; height: 86%;"
          width="4"
          v-bind:color="!hidden ? color : 'grey lighten-2'"
        >
        </v-card>
        <v-row align="center" justify="space-between" class="pb-1 flex-nowrap" no-gutters>
          <span class="text-body-2 font-weight-medium text-uppercase text-truncate">
            <v-icon v-bind:color="!hidden ? color : 'grey lighten-2'" class="mr-1" size="16">{{ icon }}</v-icon>
            <span>{{ METRICS[metricToDisplay.kind].commonName }}</span>
          </span>

          <v-icon small v-if="!hidden">mdi-eye</v-icon>
          <v-icon small v-if="hidden">mdi-eye-off</v-icon>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row class="" no-gutters>
              <span class="text-h6 font-weight-medium"
                >{{ metricToDisplay.displayValue }}<span class="text-body-2 font-weight-regular ml-1">{{ metricToDisplay.units }}</span></span
              >
            </v-row>
            <v-row class="" style="margin-top:-6px" no-gutters>
              <span class="text-caption font-weight-regular">{{ timestamp }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'
import consts from '@/consts'

export default {
  props: {
    metricToDisplay: Object,
    color: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      hidden: false,
      METRICS: consts.METRICS,
    }
  },
  computed: {
    icon() {
      if (consts.METRICS[this.metricToDisplay.kind].icon) {
        return consts.METRICS[this.metricToDisplay.kind].icon
      }
      return 'mdi-water'
    },
    timestamp() {
      return this.relativeTime(this.metricToDisplay.ts)
    },
  },
  methods: {
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
  },
}
</script>

<style></style>
