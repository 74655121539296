<template>
  <v-row no-gutters>
    <v-col>
      <patient-notes />

      <v-row v-for="(note, index) in deprecatedNotes" :key="index" no-gutters>
        <chart-note-card class="mb-4" :note="note" />
      </v-row>
    </v-col>

    <portal to="patientFeedSecondRightPanel">
      <chart-notes-side-card class=" mr-4" :noteTypes="noteTypes" />
    </portal>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import ChartNoteCard from '@/components/ChartNoteCard'
import bus from '@/core/helpers/bus'
import ChartNotesSideCard from './ChartNotesSideCard.vue'
import PatientNotes from '@/components/bridgechartnotes/PatientNotes.vue'

export default {
  mixins: [HelperMixin],
  components: {
    ChartNoteCard,
    ChartNotesSideCard,
    PatientNotes,
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('org', ['settings', 'users']),
    ...mapState('patient', ['patient', 'deprecatedNotes']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    savedNoteTemplates() {
      // backwards compat
      return Object.values(this.settings.chartNoteTemplates || {})
    },
    noteTypes() {
      const noteTypesDictionary = {}
      for (const note of this.deprecatedNotes) {
        if (!note.fullNote.data.templateID) {
          continue
        }
        noteTypesDictionary[note.fullNote.data.templateID] = noteTypesDictionary[note.fullNote.data.templateID] || 0
        noteTypesDictionary[note.fullNote.data.templateID]++
      }
      const matchedTypes = []
      for (const key of Object.keys(noteTypesDictionary)) {
        // get the text name of the key.
        this.savedNoteTemplates.forEach(template => {
          if (template.id === key) {
            matchedTypes.push({ title: template.title, count: noteTypesDictionary[key] })
          }
        })
      }
      return matchedTypes
    },
  },
  methods: {
    createNewNote(type) {
      if (type) {
        bus.$emit('addNoteWithType', type)
      } else {
        bus.$emit('addNote')
      }
    },
  },
}
</script>
